export interface Theme {
   id: string;
   primary: Shade;
   secondary: Shade;
   teritiary?: Shade;
   accentPrimary: Shade;
   accentSecondary: Shade;
   accentTeritiary?: Shade;
}

export class Shade {
   private current: string;
   private _dark?: string;
   private _light?: string;

   constructor(current: string, dark?: string, light?: string) {
      this.current = current;
      this._dark = dark;
      this._light = light;
   }

   value() {
      return this.current;
   }

   dark() {
      return this.getColor(this._dark);
   }

   light() {
      return this.getColor(this._light);
   }

   private getColor(color?: string) {
      if (color) return color;
      return this.current;
   }
}
