import { getUserInfo } from "../libs/authorized";
import { FilesServiceConfig } from "../services/config";
import { FileModel } from "./File/file";

export default class FilesService {
   config: FilesServiceConfig;
   constructor(config: FilesServiceConfig) {
      this.config = config;
   }

   createNewFile(newFile: Partial<FileModel>): Promise<string> {
      return fetch(`${this.config.apiUrl}/files`, {
         method: "post",
         headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getUserInfo()?.accessToken}`,
         },
         body: JSON.stringify({
            ...newFile,
            userId: getUserInfo()?._id,
         }),
      })
         .catch((err) => Promise.reject({ message: err.message }))
         .then((res) =>
            res
               .json()
               .then((json) =>
                  res.status === 200 ? json.fileId : Promise.reject(json),
               ),
         );
   }

   getFileById(fileId: string): Promise<FileModel> {
      return fetch(`${this.config.apiUrl}/files/${fileId}`, {
         credentials: "include",
         headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getUserInfo()?.accessToken}`,
         },
      })
         .catch((err) => Promise.reject({ message: err.message }))
         .then((res) =>
            res
               .json()
               .then((json) =>
                  res.status === 200 ? json.file : Promise.reject(json),
               ),
         );
   }

   getFiles(skip: number, limit: number, requestCount: boolean): Promise<any> {
      return fetch(
         `${this.config.apiUrl}/files?skip=${skip}&limit=${limit}&${
            requestCount ? `count=${true}` : ""
         }`,
         {
            credentials: "include",
            headers: {
               "content-type": "application/json",
               Authorization: `Bearer ${getUserInfo()?.accessToken}`,
            },
         },
      )
         .catch((err) => Promise.reject({ message: err.message }))
         .then((res) =>
            res
               .json()
               .then((json) =>
                  res.status === 200 ? json : Promise.reject(json),
               ),
         );
   }

   deleteFile(fileId: string) {
      return fetch(`${this.config.apiUrl}/files/${fileId}`, {
         method: "delete",
         credentials: "include",
         headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getUserInfo()?.accessToken}`,
         },
      })
         .catch((err) => Promise.reject({ message: err.message }))
         .then((res) =>
            res
               .json()
               .then((json) =>
                  res.status === 200 ? json : Promise.reject(json),
               ),
         );
   }

   updateFile(file: Partial<FileModel>) {
      const { _id, ...rest } = file;
      return fetch(`${this.config.apiUrl}/files/${file._id}`, {
         method: "PATCH",
         credentials: "include",
         headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getUserInfo()?.accessToken}`,
         },
         body: JSON.stringify(rest),
      })
         .catch((err) => Promise.reject({ message: err.message }))
         .then((res) =>
            res
               .json()
               .then((json) =>
                  res.status === 200 ? json : Promise.reject(json),
               ),
         );
   }
}
