import { useEffect, useRef, useState } from "react";
import PdfService from "./pdfService";
import { GlobalConfig } from "./config";
import IndexDBService from "./indexDbService";
import { defaultText } from "./monacoService/defaultText";
import MonacoService from "./monacoService/monacoService";

export class ServiceList {
   //all apis goes here
   apiService: PdfService;

   //monaco service
   monacoService: MonacoService;

   // indexDb service
   indexDbService: IndexDBService;

   // if init function is called on this serviceList
   isEmpty: boolean;
   error: string;

   config: GlobalConfig;
   schema: any;
   jsonText: string;

   constructor(config: GlobalConfig) {
      this.config = config;
      this.apiService = new PdfService(config.pdfServiceConfig);
      this.monacoService = new MonacoService(
         `http://current/${Math.random()}.json`,
      );
      this.isEmpty = true;
      this.error = "";
      this.indexDbService = new IndexDBService(config.indexDbConfig);
      this.jsonText = defaultText;
   }

   init() {
      // actual initialisations happen here
      return this.apiService
         .getSchema()
         .then((schema) => {
            this.schema = schema;

            // set with basicTemplate initially
            this.monacoService.setSchema(schema.basicTemplate);
         })
         .then(() => (this.isEmpty = false))
         .then(() =>
            // getting JsonText
            this.indexDbService.init().then(() =>
               this.indexDbService.getJsonText().then((text) => {
                  if (text) this.jsonText = text;
               }),
            ),
         );
   }

   clone() {
      let newService = Object.create(ServiceList.prototype) as ServiceList;
      newService.apiService = this.apiService;
      newService.monacoService = this.monacoService;
      newService.indexDbService = this.indexDbService;
      newService.isEmpty = false;
      newService.error = this.error;
      newService.jsonText = this.jsonText;
      return newService;
   }

   dispose() {
      this.monacoService.dispose();
   }
}

export const useReactiveServiceList = (initial: ServiceList) => {
   const serviceListRef = useRef(initial);
   const [serviceListResult, setServiceListResult] = useState(initial);

   useEffect(() => {
      const serviceList = serviceListRef.current;
      serviceList
         .init()
         .then((_) => serviceList.clone())
         .then(setServiceListResult)
         .catch((err) => {
            serviceList.error = err?.message ?? err;
            setServiceListResult(serviceList.clone());
         });
   }, [serviceListRef]);

   return serviceListResult;
};
