import { getUserInfo } from "./authorized";

const customFetch: (r: string, p?: RequestInit) => Promise<Response> = (
   endPoint,
   p = {},
) => {
   const { headers: addingHeaders, ...addingProperties } = p;
   return fetch(endPoint, {
      mode: "cors",
      headers: {
         ...addingHeaders,
         Authorization: `Bearer ${getUserInfo()?.accessToken ?? ""}`,
      },
      ...addingProperties,
   })
      .catch(() =>
         navigator.onLine
            ? Promise.reject({
                 code: 500,
                 message: "server might be down. please check after sometime",
              })
            : Promise.reject({
                 code: 0,
                 message: "you are offline!",
              }),
      )
      .then((res) => {
         if (res.ok) return res;

         //if error is in json format
         const contentType = res.headers.get("content-type");
         if (contentType?.includes("application/json"))
            return res.json().then((val) =>
               Promise.reject({
                  code: res.status,
                  ...val,
               }),
            );

         //  if error is in plainText
         return res.text().then((message) =>
            Promise.reject({
               code: res.status,
               message,
            }),
         );
      });
};

export default customFetch;
