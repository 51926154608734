import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MouseEventHandler } from "react";
import ReactTooltip from "react-tooltip";
import { useTheme } from "../themes/context";
import "./actionButton.css";

interface ActionIconProps {
   className?: string;
   icon: IconProp;
   text?: string;
   fontSize?: number;
   backgroundColor?: string;
   onClick?: MouseEventHandler;
   disabled?: boolean;
}

export const ActionIcon = ({
   className,
   icon,
   fontSize,
   backgroundColor,
   text,
   onClick,
   disabled = false,
}: ActionIconProps) => {
   const [theme] = useTheme();

   return (
      <div
         data-tip={text}
         className={"action " + className ?? ""}
         style={{
            fontSize: fontSize ?? 30,
            backgroundColor: backgroundColor ?? theme.secondary.value(),
            borderRadius: fontSize ?? 30,
            color: theme.accentPrimary.light(),
         }}
         onClick={(e) => (disabled ? null : onClick?.(e))}
      >
         <FontAwesomeIcon icon={icon} />
         <ReactTooltip
            event="mouseover"
            eventOff="mouseout"
            backgroundColor={theme.secondary.value()}
            textColor={theme.accentTeritiary?.value()}
            effect="solid"
            place="bottom"
            disable={disabled}
         />
      </div>
   );
};
