import MonacoEditor from "react-monaco-editor";
import { defaultText } from "../../../services/monacoService/defaultText";
import { useTheme } from "../../../components/themes/context";
import useSize from "../../../libs/customHooks/useSize";
import { useRef } from "react";
import "./editor.scss";

export default function Editor() {
   const [theme] = useTheme();
   const divRef = useRef(null);
   const { width } = useSize(divRef) as any;

   return (
      <div className="editor-container">
         <div className="editor-mask"></div>
         <div
            ref={divRef}
            className="editor"
            style={{ backgroundColor: theme.accentPrimary.value() }}
         >
            <MonacoEditor
               language="json"
               theme={theme.id}
               value={defaultText}
               width={width}
               options={{
                  selectOnLineNumbers: true,
                  minimap: { enabled: false },
                  fontSize: 10,
                  wordWrap: "bounded",
                  wordWrapColumn: 120,
                  lineNumbers: "off",
                  readOnly: true,
                  renderLineHighlight: "none",
               }}
            />
         </div>
      </div>
   );
}
