import { Theme } from "../../components/themes/theme";

export default function Loading({ theme }: { theme: Theme }) {
   return (
      <div
         style={{
            display: "table",
            width: "100%",
            height: "inherit",
            color: theme.accentSecondary.light(),
            backgroundColor: theme.accentPrimary.value(),
            fontSize: 40,
         }}
      >
         <div
            style={{
               textAlign: "center",
               display: "table-cell",
               verticalAlign: "middle",
            }}
         >
            loading...
         </div>
      </div>
   );
}
