import { createContext, ReactNode, useContext } from "react";
import { getConfig } from "./config";
import { ServiceList, useReactiveServiceList } from "./serviceList";

const MakeServiceListContext = (serviceList: ServiceList) => {
   const serviceListContext = createContext(serviceList);

   const useServiceList = () => {
      return useContext(serviceListContext);
   };

   interface ServiceContextProviderProps {
      children: ReactNode | ReactNode[];
   }

   const ServiceContextProvider = ({
      children,
   }: ServiceContextProviderProps) => {
      const reactiveServiceList = useReactiveServiceList(serviceList);

      return (
         <serviceListContext.Provider value={reactiveServiceList}>
            {children}
         </serviceListContext.Provider>
      );
   };

   return [ServiceContextProvider, useServiceList] as [
      ({ children }: ServiceContextProviderProps) => JSX.Element,
      () => ServiceList,
   ];
};

const [ServiceContextProvider, useServiceList] = MakeServiceListContext(
   new ServiceList(getConfig()),
);
export { useServiceList, ServiceContextProvider };
