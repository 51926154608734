import JsonEditor from "./JsonEditor/JsonEditor";
import { Landing } from "./Landing/Landing";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
// import AuthVerification from "./Login/AuthVerification";
// import { getConfig } from "./services/config";
// import AuthService from "./Login/authService";
// import Logout from "./Login/Logout";
// import { Login } from "./Login/Login";
// import Files from "./Files/Files";

// const { authServiceConfig } = getConfig();
// const authService = new AuthService(authServiceConfig);

import Header from "./components/Header/Header";

// main app here
export const App = () => {
   return (
      <div>
         <BrowserRouter>
            <Header height={(7 * window.visualViewport.height) / 100} />
            <Routes>
               <Route path="" element={<Landing />} />

               {/* route for files -> to be implemented with server */}
               {/* <Route path="files"> */}
               {/* <Route path="" element={<Files />} /> */}
               {/* <Route path="demo" element={<JsonEditor />} /> */}
               {/* </Route> */}

               <Route path="files/demo" element={<JsonEditor />} />

               {/* authorization routes -> to be implemented with server */}
               {/* <Route path="login">
                  <Route path="" element={<Login />} />
                  <Route
                     path="github"
                     element={
                        <AuthVerification
                           authProvider="Github"
                           authVerifier={(args) =>
                              authService.verifyGithubAuth(args)
                           }
                        />
                     }
                  />
               </Route>
               <Route
                  path="logout"
                  element={<Logout revoker={() => authService.logout()} />}
               /> */}

               {/* default routes */}
               <Route path="*" element={<Navigate to="/" />} />
            </Routes>
         </BrowserRouter>
      </div>
   );
};
