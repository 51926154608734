import { Fragment, ReactNode } from "react";
import { ActionIcon } from "../../components/ActionButton/ActionIcon";
import {
   faBookOpen,
   faDownload,
   // faEdit,
   // faGlobe,
   faLink,
   // faGlobe,
   // faLink,
   // faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FileModel } from "../File/file";
import { useNavigate } from "react-router-dom";
import { getConfig } from "../../services/config";
import FilesService from "../filesService";
import ConfirmPrompt from "../../components/ConfirmPrompt";
import { usePdfActionsReducer } from "./reducer";
import Loading from "../../components/Loading";
import RenameFileDialogue from "./RenameFileDialogue";

interface PdfActionControlsProps {
   selectedFile?: FileModel;
   pdfUrl: string;
   hideOpen?: boolean;
   updateFile?: (x: FileModel | null) => void;
   isLoading?: boolean;
}

const { filesServiceConfig } = getConfig();
const filesService = new FilesService(filesServiceConfig);

export default function PdfActionControls({
   selectedFile,
   pdfUrl,
   hideOpen = false,
   updateFile,
   isLoading = false,
}: PdfActionControlsProps) {
   const [state, dispatch] = usePdfActionsReducer();
   const navigate = useNavigate();

   const setDialogue = (dialogue: string | undefined) =>
      dispatch(["setDialogue", { dialogue }]);

   const cancelDialogue = () => {
      if (state.dialogue === "loading") return;
      setDialogue(undefined);
   };

   const clearLoading = () => setDialogue(undefined);

   const deleteFile = () => {
      setDialogue("loading");
      if (!selectedFile) {
         return clearLoading();
      }

      filesService
         .deleteFile(selectedFile._id)
         .then(() => updateFile?.(null))
         .catch((error) =>
            alert("error while deleting file : " + error.message),
         )
         .finally(clearLoading);
   };

   const changePrivacy = () => {
      setDialogue("loading");
      if (!selectedFile) {
         return clearLoading();
      }

      selectedFile.isPublic = !selectedFile.isPublic;
      const updatingFile = {
         _id: selectedFile._id,
         isPublic: selectedFile.isPublic,
      };

      filesService
         .updateFile(updatingFile)
         .then(() => updateFile?.(selectedFile))
         .catch((error) =>
            alert("error while changing privacy : " + error.message),
         )
         .finally(clearLoading);
   };

   const renameFile = (newTitle: string) => {
      setDialogue("loading");
      if (!selectedFile) {
         return clearLoading();
      }

      const updatingFile = {
         _id: selectedFile?._id,
         title: newTitle,
      };
      selectedFile.title = newTitle;

      filesService
         .updateFile(updatingFile)
         .then(() => updateFile?.(selectedFile))
         .catch((error) => alert("error while renaming : " + error.message))
         .finally(clearLoading);
   };

   const disabled = !selectedFile || isLoading;
   const backgroundColor = disabled ? "gray" : undefined;

   return (
      <Fragment>
         {hideOpen ? (
            ""
         ) : (
            <ActionIcon
               icon={faBookOpen}
               fontSize={18}
               text="open"
               backgroundColor={backgroundColor}
               onClick={() => navigate(selectedFile?._id ?? "")}
               disabled={disabled}
            />
         )}
         {/* <ActionIcon
            icon={faEdit}
            fontSize={18}
            text="rename"
            onClick={() => setDialogue("rename")}
            backgroundColor={backgroundColor}
            disabled={disabled}
         /> */}
         <ALink
            href={pdfUrl}
            target="_blank"
            rel="noreferrer"
            component={
               <ActionIcon
                  icon={faLink}
                  fontSize={18}
                  text={
                     selectedFile?.isPublic ? "get link" : "should be public"
                  }
                  backgroundColor={
                     selectedFile?.isPublic ? backgroundColor : "grey"
                  }
                  disabled={disabled}
               />
            }
            disabled={disabled || !selectedFile?.isPublic}
         />

         <ALink
            href={pdfUrl}
            download={"resume.pdf"}
            component={
               <ActionIcon
                  icon={faDownload}
                  fontSize={18}
                  text="download"
                  backgroundColor={backgroundColor}
                  disabled={disabled}
               />
            }
            disabled={disabled}
         />
         {/* <ActionIcon
            icon={faGlobe}
            fontSize={18}
            text="privacy"
            onClick={() => setDialogue("privacy")}
            backgroundColor={backgroundColor}
            disabled={disabled}
         />
         <ActionIcon
            icon={faTrash}
            fontSize={18}
            text="delete"
            onClick={() => setDialogue("delete")}
            backgroundColor={backgroundColor}
            disabled={disabled}
         /> */}
         <ConfirmPrompt
            open={state.dialogue === "delete"}
            text={`delete the file "${selectedFile?.title}" ?`}
            onCancel={cancelDialogue}
            onConfirm={deleteFile}
         />
         <ConfirmPrompt
            open={state.dialogue === "privacy"}
            text={`change the file's privacy to ${
               selectedFile?.isPublic ? "private" : "public"
            } ?`}
            onCancel={cancelDialogue}
            onConfirm={changePrivacy}
         />
         <RenameFileDialogue
            open={state.dialogue === "rename"}
            title={selectedFile?.title ?? ""}
            handleClose={cancelDialogue}
            onSubmit={renameFile}
         />
         <Loading
            open={state.dialogue === "loading"}
            text={getLoadingMessage(state.lastDialogue)}
         />
      </Fragment>
   );
}

function getLoadingMessage(state: string | undefined): string {
   switch (state) {
      case "delete":
         return "deleting the file...";

      case "privacy":
         return "updating the privacy...";

      default:
         return "";
   }
}

interface ALinkProps {
   href?: string;
   target?: string;
   rel?: string;
   download?: string;
   component: ReactNode;
   disabled: boolean;
}

function ALink({
   href,
   target,
   rel,
   download,
   component,
   disabled,
}: ALinkProps) {
   if (disabled) return <>{component}</>;

   return (
      <a href={href} target={target} rel={rel} download={download}>
         {component}
      </a>
   );
}
