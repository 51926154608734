import { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { PDFDocumentProxy } from "react-pdf/node_modules/pdfjs-dist/types/src/display/api";
import { useTheme } from "../../components/themes/context";
import LoadingPage from "./Loading";
import "./preview.css";

interface PdfPreviewProps {
   pdfUrl: string;
   isLoading: boolean;
   onLoadComplete: () => void;
}

let PdfPreview = ({ pdfUrl, isLoading, onLoadComplete }: PdfPreviewProps) => {
   const [numPages, setNumPages] = useState(0);
   const [pageNumber, setPageNumber] = useState(1);
   const [theme] = useTheme();

   function onDocumentLoadSuccess({ numPages }: PDFDocumentProxy) {
      setNumPages(numPages);
      onLoadComplete();
   }

   const leftExists = pageNumber - 1 >= 1;
   const rightExists = pageNumber + 1 <= numPages;

   return (
      <div
         className="pdfPreview"
         style={{ backgroundColor: theme.accentPrimary.value() }}
      >
         {isLoading ? <LoadingPage theme={theme} /> : ""}
         <Document
            file={pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            className={isLoading ? "" : "app-pdf-preview"}
         >
            <Page
               pageNumber={pageNumber}
               height={(93 * window.visualViewport.height) / 100}
            />
         </Document>
         {leftExists || rightExists ? (
            <div className="pdf-controls">
               <div
                  className="controls-container"
                  style={{
                     color: theme.accentSecondary.light(),
                  }}
               >
                  <PageActionButton
                     left={true}
                     setPageNumber={setPageNumber}
                     exists={leftExists}
                  />
                  <PageValues pageNumber={pageNumber} numPages={numPages} />
                  <PageActionButton
                     left={false}
                     setPageNumber={setPageNumber}
                     exists={rightExists}
                  />
               </div>
            </div>
         ) : (
            ""
         )}
      </div>
   );
};

const PageValues = ({ pageNumber, numPages }: any) => {
   return (
      <div className="page-values">
         <div>{pageNumber}</div>
         <div>{"/"}</div>
         <div>{numPages}</div>
      </div>
   );
};

const PageActionButton = ({ exists, setPageNumber, left }: any) => {
   if (exists)
      return (
         <div
            className={"page-action " + (left ? "left" : "right")}
            onClick={(_) =>
               setPageNumber((num: number) => num + (left ? -1 : 1))
            }
         >
            {left ? "<" : ">"}
         </div>
      );

   return <div className="dummy"> </div>;
};

export default PdfPreview;
