import { openDB } from "idb";
import { IndexDbConfig } from "./config";

export default class IndexDBService {
   dbName: string;
   jsonTextStoreName: string;
   dbVersion: number;

   constructor(config: IndexDbConfig) {
      this.dbName = config.dbName;
      this.jsonTextStoreName = config.jsonTextStoreName;
      this.dbVersion = config.dbVersion;
   }

   private getDb() {
      return openDB(this.dbName, this.dbVersion);
   }

   init() {
      const { jsonTextStoreName } = this;
      return openDB(this.dbName, this.dbVersion, {
         upgrade(db) {
            if (!db.objectStoreNames.contains(jsonTextStoreName)) {
               db.createObjectStore(jsonTextStoreName);
            }
         },
      });
   }

   getJsonText() {
      return this.getDb().then((db) =>
         db.get(this.jsonTextStoreName, "defaultText"),
      );
   }

   setJsonText(jsonText: string) {
      return this.getDb().then((db) =>
         db.put(this.jsonTextStoreName, jsonText, "defaultText"),
      );
   }

   clearJsonText() {
      return this.getDb().then((db) =>
         db.delete(this.jsonTextStoreName, "defaultText"),
      );
   }
}
