import {
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   TextField,
} from "@mui/material";
import { useState } from "react";

interface RenameFileDialogueProps {
   open: boolean;
   title: string;
   handleClose: () => void;
   onSubmit: (title: string) => void;
}

export default function RenameFileDialogue({
   open,
   handleClose,
   onSubmit,
   title: initialTitle,
}: RenameFileDialogueProps) {
   const [title, setTitle] = useState(initialTitle);

   return (
      <div>
         <Dialog open={open} onClose={handleClose}>
            <DialogTitle>rename the file</DialogTitle>
            <DialogContent>
               <TextField
                  autoFocus
                  margin="dense"
                  id="title"
                  label="title"
                  fullWidth
                  variant="standard"
                  defaultValue={initialTitle}
                  onChange={(e) => setTitle(e.target.value)}
               />
            </DialogContent>
            <DialogActions>
               <Button onClick={handleClose}>Cancel</Button>
               <Button onClick={() => onSubmit(title)}>Rename</Button>
            </DialogActions>
         </Dialog>
      </div>
   );
}
