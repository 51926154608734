let defaultTextJSON = {
   name: "Varshneya Bhushan",
   social: {
      email: "me@bhushan.fun",
      website: "https://me.bhushan.fun",
      linkedin: "https://bit.ly/linkedInVar",
   },
   experience: [
      {
         title: "Testbook",
         link: "https://testbook.com",
         subTitle: "Backend Developer",
         caption1: "Aug 2019 -- present",
         caption2: "Navi Mumbai, India",
         points: [
            "developed and maintained APIs for several features like masterclasses, study stats etc that help students learn better in the platform",
            "made tools to write better automated scripts which can be used by fellow developers to do several tasks like fixing data inconsistency, data migration etc",
         ],
      },
      {
         title: "CDAC",
         subTitle: "Software Developer - Intern",
         link: "https://www.cdac.in/",
         caption1: "May 2018 -- July 2018",
         caption2: "Pune, India",
         points: [
            "developed a service IRM: Issue Report Management System, from Designing to Testing, using NodeJS, MySQL and frontend technologies - HTML, CSS, Javascript",
         ],
      },
   ],
   projects: [
      {
         title: "CodeJS",
         caption1: "https://js.bhushan.fun",
         points: [
            "web app to write and execute javascript on the page. Projects written can be saved to a personal account",
         ],
      },
      {
         title: "Chatingplace",
         caption1: "https://chat.bhushan.fun",
         points: [
            "web app which is a simple but a decent group chat app. Used sockets for live communication",
         ],
      },
      {
         title: "KudoSudoku",
         caption1: "https://bit.ly/kudosudoku",
         points: [
            "python package that solves a given sudoku puzzle using constraint programming",
         ],
      },
      {
         title: "ImageScrapper",
         caption1: "https://bit.ly/imgscrpr",
         points: ["a chrome extension to scrape images from any website"],
      },
   ],
   education: [
      {
         title: "Indian Institute of Technology, Guwahati",
         subTitle: "Bachelor of Technology, Mathematics and Computing",
         caption1: "July 2015 - May 2020",
         caption2: "Guwahati, India",
         link: "https://www.iitg.ac.in/",
      },
   ],
   skills: [
      {
         heading: "Languages",
         value: "Golang, Javascript (NodeJS), C, Python",
      },
      {
         heading: "Web technologies",
         value: "HTML, CSS, Javascript - ES7, Typescript, ReactJS, Angular (beginner)",
      },
      {
         heading: "Database management",
         value: "MongoDB, Redis, Elasticsearch, Firebase, MySQL",
      },
      {
         heading: "Version Control",
         value: "Git, Github, Bitbucket",
      },
      {
         heading: "Operating system",
         value: "Linux, Windows,, Mac OS",
      },
      {
         heading: "Others",
         value: "Photoshop, Markdown",
      },
   ],
   achievements: [
      {
         heading: "Joint Entrance Examination 2015",
         value: "Secured all India rank 1308 among 0.15 million candidates appeared for the test",
      },
      {
         heading: "Mathematical Olympiad 2011 AP",
         value: "Secured state rank 9 in 39th mathematical olympiad held throughout the state of Andhra Pradesh, India.",
      },
      {
         heading: "International Maths Olympiad 2010",
         value: "Secured city rank 1 and international rank 2428 in 3rd international maths olympiad.",
      },
   ],
};

export let defaultText = JSON.stringify(defaultTextJSON, null, "\t");
