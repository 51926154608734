import { useReducer } from "react";
import { FileModel } from "../Files/File/file";
import { defaultText } from "../services/monacoService/defaultText";

export interface JsonEditorState {
   isLoading: boolean;
   isSaving: boolean;
   pdfUrl: string;
   file?: FileModel;
   jsonText: string;
   errorMessage: string;
}

const initialState: JsonEditorState = {
   isLoading: false,
   isSaving: false,
   pdfUrl: "",
   jsonText: defaultText,
   errorMessage: "",
};

type action = [string, Partial<JsonEditorState>] | string;

function reducer(state: JsonEditorState, action: action): JsonEditorState {
   let type = typeof action == "string" ? action : action[0];
   let payload = typeof action == "object" ? action[1] : {};

   switch (type) {
      case "initialLoading":
         return {
            ...state,
            isLoading: true,
            jsonText: payload.jsonText ?? "",
            file: payload.file,
         };

      case "saving":
         return { ...state, isSaving: true };

      case "saved":
         return { ...state, isSaving: false };

      case "loading":
         return { ...state, isLoading: true };

      case "loadError":
         return {
            ...state,
            errorMessage: payload.errorMessage ?? "",
            isLoading: false,
         };

      case "loaded":
         return { ...state, isLoading: false };

      case "pdfUrlChange":
         const newFile = state.file;
         if (newFile) newFile.content = state.jsonText;
         return {
            ...state,
            pdfUrl: payload.pdfUrl ?? state.pdfUrl,
            file: newFile,
         };

      case "typing":
         if (state.isLoading) return state;
         return {
            ...state,
            jsonText: payload.jsonText ?? "",
         };

      default:
         return state;
   }
}

export function useJsonEditorReducer() {
   return useReducer(reducer, initialState);
}
