import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import useSize from "../../../libs/customHooks/useSize";
import { useRef, useState } from "react";
import "./pdfPreview.scss";

export default function PdfPreview() {
   const divRef = useRef(null);
   const { width } = useSize(divRef) as any;
   const [loaded, setLoaded] = useState(false);

   return (
      <div className="pdf" ref={divRef}>
         <div className="pdf-overlay"></div>
         <div className={loaded ? "pdf-content" : ""}>
            {loaded ? (
               ""
            ) : (
               <div
                  style={{
                     backgroundColor: "white",
                     width: width,
                     height: (297 * (width ?? 0)) / 210,
                  }}
               ></div>
            )}
            <Document
               file={"./preview.pdf"}
               onLoadSuccess={(_) => setLoaded(true)}
            >
               <Page pageNumber={1} width={width} />
            </Document>
         </div>
      </div>
   );
}
