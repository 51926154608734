import { monaco } from "react-monaco-editor";
import { Theme, Shade } from "./theme";

export const redHoney: Theme = {
   id: "red-honey",
   primary: new Shade("#BC4B51", "#a31515", "#e2878b"),
   secondary: new Shade("#55868C", "#146470", "#a1b6b9"),
   accentPrimary: new Shade("#e2dbcc", "#e2c6a6", "#f0e9e2"),
   accentSecondary: new Shade("#303030", "#242424", "#5f5f5f"),
   accentTeritiary: new Shade("#ffffff"),
};

export const themes = new Map<string, Theme>();
themes.set("red-honey", redHoney);

// register themes in monaco
themes.forEach((theme) =>
   monaco.editor.defineTheme(theme.id, {
      base: "vs",
      inherit: true,
      rules: [
         {
            token: "string.key.json",
            foreground: theme.primary.dark(),
         },
         {
            token: "string.value.json",
            foreground: theme.accentSecondary.dark(),
         },
      ],
      colors: {
         "editor.background": theme.accentPrimary.value(),
      },
   }),
);
