import { useTheme } from "../../components/themes/context";

export const Typo1 = () => {
   return (
      <div className="typo-1">
         <Bracket content={"{"} />
         <p> Write your resume content in JSON </p>
         <Bracket content={"}"} />
      </div>
   );
};

const Bracket = ({ content }: any) => {
   const [theme] = useTheme();
   return (
      <div className="bracket" style={{ color: theme.primary.value() }}>
         {content}
      </div>
   );
};
