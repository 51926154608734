export interface GlobalConfig {
   pdfServiceConfig: PdfServiceConfig;
   indexDbConfig: IndexDbConfig;
   authServiceConfig: AuthServiceConfig;
   filesServiceConfig: FilesServiceConfig;
}

export interface PdfServiceConfig {
   apiUrl: string;
}

export interface IndexDbConfig {
   dbName: string;
   dbVersion: number;
   jsonTextStoreName: string;
}

export interface AuthServiceConfig {
   apiUrl: string;
   githubConfig: GithubConfig;
}

export interface FilesServiceConfig {
   apiUrl: string;
}

export interface GithubConfig {
   clientId: string;
   redirectUrl: string;
}

const indexDbConfig: IndexDbConfig = {
   dbName: "localStorage",
   dbVersion: 1,
   jsonTextStoreName: "jsonTexts",
};

const developmentConfig: GlobalConfig = {
   indexDbConfig,
   pdfServiceConfig: {
      apiUrl: "http://localhost:8082",
   },
   authServiceConfig: {
      apiUrl: "http://localhost:8083",
      githubConfig: {
         clientId: "5003c51f943d379cbf31",
         redirectUrl: "http://localhost:3000/login/github",
      },
   },
   filesServiceConfig: {
      apiUrl: "http://localhost:8084",
   },
};

const productionConfig: GlobalConfig = {
   indexDbConfig,
   pdfServiceConfig: {
      apiUrl: "https://pdf-api.simpleresume.dev",
   },
   authServiceConfig: {
      apiUrl: "http://localhost:8083",
      githubConfig: {
         clientId: "5003c51f943d379cbf31",
         redirectUrl: "http://localhost:3000/login/github",
      },
   },
   filesServiceConfig: {
      apiUrl: "http://localhost:8084",
   },
};

export const getConfig = () => {
   switch (process.env.NODE_ENV) {
      case "production":
         return productionConfig;
      default:
         return developmentConfig;
   }
};
