import { useNavigate } from "react-router-dom";
import "./logo.css";

interface LogoProps {
   fontSize: number;
   className?: string;
   iconTextColor?: string;
   backgroundColor?: string;
   textColor?: string;
}

let Logo = ({
   fontSize,
   className,
   iconTextColor,
   backgroundColor,
   textColor,
}: LogoProps) => {
   const navigate = useNavigate();
   return (
      <div className={"logo " + (className ?? "")} style={{ fontSize }}>
         <div
            className="icon"
            style={{
               backgroundColor,
               color: iconTextColor,
               cursor: "pointer",
            }}
            onClick={() => navigate("")}
         >
            SR
         </div>
         <div
            className="caption"
            onClick={() => navigate("")}
            style={{
               color: textColor,
               cursor: "pointer",
            }}
         >
            {" "}
            SimpleResume{" "}
         </div>
      </div>
   );
};

export default Logo;
