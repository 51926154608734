import React, { createContext, useContext, useState } from "react";
import { Theme } from "./theme";

const themeContext = createContext<Theme | null>(null);

type themeSetter = (x: string) => void;
const setThemeContext = createContext<themeSetter | null>(null);

interface ThemeContextProviderProps {
   children: React.ReactNode[] | React.ReactNode;
   defaultTheme: Theme;
   themeSet: Map<string, Theme>;
}

export const ThemeContextProvider = ({
   children,
   defaultTheme,
   themeSet,
}: ThemeContextProviderProps) => {
   const [theme, setThemeObject] = useState(defaultTheme);
   const setTheme = (themeTitle: string) => {
      setThemeObject(themeSet.get(themeTitle) ?? defaultTheme);
   };

   return (
      <themeContext.Provider value={theme}>
         <setThemeContext.Provider value={setTheme}>
            {children}
         </setThemeContext.Provider>
      </themeContext.Provider>
   );
};

type useThemeResult = [Theme, themeSetter];

export const useTheme = (): useThemeResult => {
   let theme = useContext(themeContext);
   let setTheme = useContext(setThemeContext);

   if (theme && setTheme) return [theme, setTheme];
   else {
      throw new Error("theme is null");
   }
};
