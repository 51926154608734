import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import FilesService from "../Files/filesService";
import { getUserInfo } from "../libs/authorized";
import { getConfig } from "../services/config";
import DemoSection from "./demoSection/demoSection";
import "./landing.scss";

export function Landing() {
   return (
      <div>
         <div className="demo-container">
            <DemoSection />
            <ConvertButton />
         </div>
      </div>
   );
}

const { filesServiceConfig } = getConfig();
const filesService = new FilesService(filesServiceConfig);

const ConvertButton = () => {
   const navigate = useNavigate();
   const [isLoading, setLoading] = useState(false);

   const onClick = useCallback(() => {
      const userInfo = getUserInfo();
      if (!userInfo) {
         navigate("/files/demo");
         // navigate("/files/untitled");
         return;
      }

      setLoading(true);
      // get first file's link and navigate to it
      filesService
         .getFiles(0, 1, false)
         .then(({ files: [file] }) => {
            navigate(`/files/${file._id}`);
         })
         .catch((error) => {
            setLoading(false);
            alert("error has occurred : " + error.message);
         });
   }, [navigate, setLoading]);

   return (
      <button className="convert" onClick={onClick}>
         {isLoading
            ? "loading..."
            : getUserInfo()
            ? "Start Writing"
            : "Try For Free!"}
      </button>
   );
};
