import GoButton from "../../components/GoButton/GoButton";

interface ActionControlsProps {
   isLoading: boolean;
   isSaving: boolean;
   reLoadPdf: () => void;
   reLoadText: () => void;
   saveText: () => void;
}

export default function ActionControls({
   isLoading,
   reLoadPdf,
   reLoadText,
}: ActionControlsProps) {
   return (
      <div className="controls">
         <GoButton onClick={reLoadText} enabled={!isLoading} text="reset" />
         <GoButton onClick={reLoadPdf} enabled={!isLoading} text="build" />
      </div>
   );
}
