import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

interface ConfirmPromptProps {
   open: boolean;
   text: string;
   onCancel: () => void;
   onConfirm: () => void;
}

export default function ConfirmPrompt({
   open,
   text,
   onCancel,
   onConfirm,
}: ConfirmPromptProps) {
   return (
      <div>
         <Dialog open={open} onClose={onCancel}>
            <DialogTitle>{text}</DialogTitle>
            <DialogActions>
               <Button onClick={onCancel}>Cancel</Button>
               <Button onClick={onConfirm}>Confirm</Button>
            </DialogActions>
         </Dialog>
      </div>
   );
}
