import "./jsonInput.css";
import MonacoEditor from "react-monaco-editor";
import { useTheme } from "../../components/themes/context";
import { useServiceList } from "../../services/context";

interface JsonInputProps {
   jsonText: string;
   onTextChange: (x: string) => void;
   isLoading: boolean;
}

let JsonInput = ({ jsonText, onTextChange, isLoading }: JsonInputProps) => {
   const [theme] = useTheme();
   const serviceList = useServiceList();

   return (
      <div
         className="jsonInput container"
         style={{ backgroundColor: theme.accentPrimary.value() }}
      >
         <MonacoEditor
            language="json"
            theme={theme.id}
            value={jsonText}
            onChange={onTextChange}
            options={{
               selectOnLineNumbers: true,
               minimap: { enabled: false },
               fontSize: 17,
               wordWrap: "bounded",
               wordWrapColumn: 120,
               lineNumbers: "off",
               model: serviceList.monacoService.monacoModel,
               readOnly: isLoading,
               renderLineHighlight: "none",
            }}
         />
      </div>
   );
};

export default JsonInput;
