import UserProfile from "../UserProfile/UserProfile";
import Logo from "../Logo/Logo";
import { useTheme } from "../themes/context";
import "./header.scss";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

interface HeaderProps {
   height: number;
}

export default function Header({ height }: HeaderProps) {
   const [theme] = useTheme();

   return (
      <div
         className="header"
         style={{
            position: "fixed",
            zIndex: 999,
            top: 0,
            backgroundColor: theme.accentSecondary.value(),
            // boxShadow: enableShadow ? "#0000002a 0px 0px 20px 0px" : "",
         }}
      >
         <Logo
            fontSize={(height - 10) / 1.75}
            textColor={theme.accentPrimary.value()}
            iconTextColor={theme.accentTeritiary?.value()}
            backgroundColor={theme.primary.value()}
         />
         <UserProfileButton />
         <ConvertError />
      </div>
   );
}

const UserProfileButton = () => {
   const [theme] = useTheme();
   const location = useLocation();
   const isEditor = location.pathname === "/files/demo";
   const navigate = useNavigate();

   return (
      <>
         {!isEditor ? (
            <button
               className="write-button"
               onClick={() => navigate("/files/demo")}
               style={{
                  background: theme.primary.value(),
                  color: theme.accentTeritiary?.value(),
               }}
            >
               {" "}
               Write{" "}
            </button>
         ) : (
            ""
         )}
      </>
   );
};

// use this as UserProfileButton when auth and file apis are ready
// eslint-disable-next-line
const UserProfileButtonBackup = () => {
   const [theme] = useTheme();
   const location = useLocation();
   const hideLogin = location.pathname === "/login";

   return (
      <>
         {!hideLogin && (
            <UserProfile
               className="controls-user-profile"
               element={
                  <Button
                     style={{
                        backgroundColor: theme.primary.value(),
                        color: theme.accentTeritiary?.value(),
                     }}
                  >
                     login
                  </Button>
               }
            />
         )}
      </>
   );
};

const ConvertError = () => {
   const [theme] = useTheme();
   return (
      <p className="convert-error" style={{ color: theme.primary.value() }}>
         {" "}
         please use bigger screen to experience this site{" "}
      </p>
   );
};
