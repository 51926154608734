import { Dialog, DialogTitle } from "@mui/material";

interface LoadingProps {
   open: boolean;
   text: string;
}

export default function Loading({ open, text }: LoadingProps) {
   return (
      <div>
         <Dialog open={open}>
            <DialogTitle>{text}</DialogTitle>
         </Dialog>
      </div>
   );
}
