import { useTheme } from "../../components/themes/context";
import { Link } from "react-router-dom";
import "./windowError.scss";

interface WindowErrorProps {
   message: string;
   error: string;
   isLoading: boolean;
}

export default function WindowError({
   message = "",
   isLoading,
   error = "",
}: WindowErrorProps) {
   const [theme] = useTheme();

   let buttonClass = "button ";
   if (!isLoading) buttonClass += "loaded ";
   if (error !== "") buttonClass += "error ";

   const windowClass =
      "window-error " + (message + error === "" ? "hidden" : "visible");

   return (
      <div
         className={windowClass}
         style={{ backgroundColor: theme.accentPrimary.value() }}
      >
         <div className="content">
            <h1 style={{ color: theme.accentSecondary.light() }}>
               {message + error}
            </h1>
            <div>
               <Link to="/">
                  <button
                     className={buttonClass}
                     style={{
                        color: theme.accentTeritiary?.value(),
                        backgroundColor: theme.primary.value(),
                     }}
                  >
                     Go to home page
                  </button>
               </Link>
            </div>
         </div>
      </div>
   );
}
