import { useReducer } from "react";

export interface PdfActionsState {
   dialogue?: string;
   lastDialogue?: string;
}

type action = [string, Partial<PdfActionsState>] | string;

function pdfActionsReducer(
   state: PdfActionsState,
   action: action,
): PdfActionsState {
   let type = typeof action == "string" ? action : action[0];
   let payload = typeof action == "object" ? action[1] : {};

   switch (type) {
      case "setDialogue":
         return {
            ...state,
            lastDialogue: state.dialogue,
            dialogue: payload.dialogue,
         };
      default:
         return { ...state };
   }
}

const pdfActionsInitialState: PdfActionsState = {};

export function usePdfActionsReducer() {
   return useReducer(pdfActionsReducer, pdfActionsInitialState);
}
