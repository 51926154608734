import "./jsonEditor.scss";
import "react-toastify/dist/ReactToastify.css";

import PdfPreview from "./PdfPreview/PdfPreview";
import JsonInput from "./JsonInput/JsonInput";

import { useCallback, useEffect } from "react";

import { toast, ToastContainer } from "react-toastify";
import { useTheme } from "../components/themes/context";

import { useServiceList } from "../services/context";
import { useJsonEditorReducer } from "./reducer";
import WindowError from "./WindowError/WindowError";
import { defaultText } from "../services/monacoService/defaultText";
import ActionControls from "./ActionControls/ActionControls";
import { useNavigate, useParams } from "react-router-dom";
import { getConfig } from "../services/config";
import FilesService from "../Files/filesService";
import PdfActionControls from "../Files/PdfActions/PdfActions";
import { FileModel } from "../Files/File/file";

const { filesServiceConfig } = getConfig();
const filesService = new FilesService(filesServiceConfig);

function JsonEditor() {
   const [state, dispatch] = useJsonEditorReducer();
   const serviceList = useServiceList();

   const [theme] = useTheme();

   const errorToast = useCallback(
      (msg: String) =>
         toast.error(msg, { position: "bottom-right", theme: "dark" }),
      [],
   );

   // path should be files/{fileId}
   const { fileId = "" } = useParams();

   // initialLoad, loads savedText
   useEffect(() => {
      if (serviceList.isEmpty) return;

      const defaultText = serviceList.jsonText;
      const demoFile = {
         isPublic: true,
         _id: "demo",
         content: defaultText,
         templateId: 1,
      } as FileModel;

      if (fileId === "demo") {
         dispatch([
            "initialLoading",
            { jsonText: defaultText, file: demoFile },
         ]);
         return;
      }

      filesService
         .getFileById(fileId)
         .then((file) =>
            dispatch([
               "initialLoading",
               {
                  jsonText:
                     JSON.stringify(file.content, null, "\t") || defaultText,
                  file,
               },
            ]),
         )
         .catch((error) => {
            dispatch([
               "initialLoading",
               { jsonText: defaultText, file: demoFile },
            ]);
            console.log(error);
         });
   }, [fileId, serviceList, dispatch]);

   // when some error occurs
   useEffect(() => {
      if (state.errorMessage.trim().length !== 0)
         errorToast(state.errorMessage);
   }, [state.errorMessage, errorToast]);

   const showError = useCallback(
      (errorMessage: string) => dispatch(["loadError", { errorMessage }]),
      [dispatch],
   );

   const navigate = useNavigate();

   // error handling when pdf is being loaded
   const handleError = useCallback(
      (e) => {
         switch (e.code) {
            case 0:
               showError(e.message);
               break;

            case 500:
               // need not show error, as it will be shown in full window error
               // showError(e.message)
               break;

            case 400:
               if (e.type == null || e.type === "jsonSyntax") {
                  showError(e.message);
                  break;
               }

               if (e.type === "syntax") {
                  const { instancePath, message } = e.message[0];
                  showError(
                     `${instancePath ?? ""}${
                        instancePath ? " : " : ""
                     }${message}`,
                  );
                  break;
               }

               console.log(e);
               showError("unknown error. please check console");
               break;

            case 401:
               localStorage.clear();
               navigate(`/login?state=logout&message=${e.message}`);
               break;

            default:
               console.log(e);
               showError("unknown error. please check console");
         }
      },
      [showError, navigate],
   );

   // when state is loading, we call getPdfFromJson and set state to loaded
   useEffect(() => {
      // ignore when loading is finished
      if (state.isLoading === false || serviceList.isEmpty) return;

      // when text is empty
      if (state.jsonText.trim().length === 0) {
         showError("given text is empty, please load something");
         return;
      }

      serviceList.apiService
         .getPdfFromJson(state.jsonText, fileId, "basicTemplate")
         .then((x) => dispatch(["pdfUrlChange", { pdfUrl: x }]))
         .catch(handleError);
   }, [
      state.isLoading,
      serviceList,
      state.jsonText,
      dispatch,
      showError,
      handleError,
      fileId,
   ]);

   const reLoadPdf = useCallback(() => {
      if (serviceList.isEmpty) return;

      let jsonErrors = serviceList.monacoService.getErrors();
      if (jsonErrors.length !== 0) {
         for (let jsonError of jsonErrors)
            errorToast(`check json : ${jsonError.message}`);
         return;
      }

      dispatch("loading");
   }, [serviceList, errorToast, dispatch]);

   const loadingMessage = serviceList.isEmpty ? "loading services.." : "";

   // function changes as we type some text
   const saveText = useCallback(() => {
      dispatch("saving");
      serviceList.indexDbService
         .setJsonText(state.jsonText)
         .then(() => {
            // saves text to servicelist so that, when page is visited again from home,
            // it loads the correct text
            serviceList.jsonText = state.jsonText;
            dispatch("saved");
         })
         .catch((e) => dispatch(["loadError", { errorMessage: e }]));
   }, [dispatch, serviceList, state.jsonText]);

   useEffect(() => {
      // save only when state is loading (prevent saving when typing..)
      if (!state.isLoading || state.pdfUrl.length === 0) return;

      saveText();
   }, [state.pdfUrl, state.isLoading, saveText]);

   return (
      <div style={{ backgroundColor: theme.teritiary?.value() }}>
         <WindowError
            isLoading={serviceList.isEmpty}
            message={loadingMessage}
            error={serviceList.error}
         />
         <div className="grid-container">
            <div
               className="grid-action-controls grid-controls"
               style={{ backgroundColor: theme.accentPrimary?.value() }}
            >
               <ActionControls
                  isLoading={state.isLoading}
                  isSaving={state.isSaving}
                  reLoadPdf={() => reLoadPdf()}
                  saveText={saveText}
                  reLoadText={() =>
                     dispatch(["typing", { jsonText: defaultText }])
                  }
               />
            </div>
            <div
               className="grid-pdf-controls grid-controls"
               style={{ backgroundColor: theme.accentTeritiary?.value() }}
            >
               <PdfActionControls
                  selectedFile={state.file}
                  pdfUrl={state.pdfUrl}
                  hideOpen={true}
                  isLoading={state.pdfUrl.length === 0}
               />
               {/* <UserProfile
                  className="user-profile"
                  onLogin={() =>
                     serviceList.indexDbService.setJsonText(state.jsonText)
                  }
                  element={
                     <GoButton
                        text="login"
                        style={{ backgroundColor: theme.primary.value() }}
                     />
                  }
               /> */}
            </div>
            <div className="grid-json-input">
               <JsonInput
                  jsonText={state.jsonText}
                  isLoading={state.isLoading}
                  onTextChange={(jsonText) =>
                     dispatch(["typing", { jsonText }])
                  }
               />
            </div>
            <div className="grid-pdf-preview">
               <PdfPreview
                  pdfUrl={state.pdfUrl}
                  isLoading={state.isLoading}
                  onLoadComplete={() => dispatch("loaded")}
               />
            </div>
            <ToastContainer />
         </div>
      </div>
   );
}

export default JsonEditor;
