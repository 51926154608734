import React, { MouseEvent } from "react";
import { useTheme } from "../themes/context";
import "./goButton.css";

interface GoButtonProps {
   onClick?: (e: MouseEvent<HTMLElement>) => void;
   enabled?: Boolean;
   text: string;
   style?: React.CSSProperties;
}

let GoButton = ({ onClick, enabled = true, text, style }: GoButtonProps) => {
   const [theme] = useTheme();

   const currentStyle = {
      backgroundColor: enabled
         ? theme.secondary.value()
         : theme.accentSecondary.light(),
      color: theme.accentPrimary.light(),
   };

   return (
      <button
         onClick={onClick}
         className="goButton"
         disabled={!enabled}
         style={{ ...currentStyle, ...style }}
      >
         {text}
      </button>
   );
};

export default GoButton;
