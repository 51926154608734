import { useTheme } from "../../components/themes/context";
import { Typo1 } from "./typo1";
import { Typo2 } from "./typo2";
import Editor from "./editor/editor";
import PdfPreview from "./pdfPreview/pdfPreview";
import "./demoSection.scss";

export default function DemoSection() {
   const [theme] = useTheme();

   return (
      <div className="demo" style={{ color: theme.secondary.value() }}>
         <div className="sub-container editor-sub-container">
            <div className="sub-container-entry">
               <Editor />
            </div>
            <div className="sub-container-entry">
               <Typo1 />
            </div>
         </div>
         <div className="sub-container pdf-sub-container">
            <div className="sub-container-entry">
               <Typo2 />
            </div>
            <div className="sub-container-entry">
               <PdfPreview />
            </div>
         </div>
      </div>
   );
}
