import { useTheme } from "../../components/themes/context";

export const Typo2 = () => {
   return (
      <div className="typo-2">
         <Content />
         <Content />
         <p> We will format that content to a resume </p>
         <Content />
         <Content />
      </div>
   );
};

const Content = () => {
   const [theme] = useTheme();
   return (
      <div
         className="content"
         style={{ color: theme.primary.value(), width: "100%" }}
      >
         <hr />
      </div>
   );
};
