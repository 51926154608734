import { monaco } from "react-monaco-editor";

// json monaco service
export default class MonacoService {
   monacoURI: monaco.Uri;
   monacoModel: monaco.editor.ITextModel | null;

   constructor(uri: string) {
      this.monacoURI = monaco.Uri.parse(uri);
      this.monacoModel = monaco.editor.createModel("", "json", this.monacoURI);
   }

   setSchema(schema: any) {
      monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
         validate: true,
         allowComments: true,
         schemaRequest: "error",
         enableSchemaRequest: true,
         schemas: [
            {
               uri: schema.$id ?? "/current",
               fileMatch: [this.monacoURI.toString()],
               schema,
            },
         ],
      });
   }

   getErrors() {
      return monaco.editor.getModelMarkers({ resource: this.monacoURI });
   }

   dispose() {
      this.monacoModel?.dispose();
   }
}
