import fetch from "../libs/fetch";
import { PdfServiceConfig } from "./config";

export default class PdfService {
   fetch: (r: string, p?: RequestInit) => Promise<Response>;

   constructor({ apiUrl }: PdfServiceConfig) {
      this.fetch = (r, p = {}) => fetch(`${apiUrl}${r}`, p);
   }

   getPdfFromJson(contentJson: string, fileId: string, templateId: string) {
      return this.fetch(`/pdf`, {
         method: "POST",
         body: `{
            "content":${contentJson},
            "options": {
               "fileId" : "${fileId}",
               "templateId" : "${templateId}"
            }
         }`,
         headers: {
            "Content-Type": "application/json",
         },
      }).then((res) => res.blob().then(URL.createObjectURL));
   }

   getPdfByFileId(fileId: string) {
      return this.fetch(`/pdf?fileId=${fileId}`).then((res) =>
         res.blob().then(URL.createObjectURL),
      );
   }

   getSchema() {
      return this.fetch(`/schema.json`).then((res) => res.json());
   }
}
