import { getCookie } from "./cookie";

const userInfoKey = "userInfo";
const accessTokenKey = "access_token";

export function getUserInfo(): any {
   const accessToken = getCookie(accessTokenKey);
   if (!accessToken) return null;
   const userInfoJson = localStorage.getItem(userInfoKey);
   if (!userInfoJson) return null;
   const userInfo = JSON.parse(userInfoJson);
   userInfo.accessToken = accessToken;
   return userInfo;
}

export function setUserInfo(userInfo: any) {
   localStorage.setItem(userInfoKey, JSON.stringify(userInfo));
}
